.light {
  --main-font-family: warnock-pro, Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  --second-font-family: GreekFallback,Calibri,"Gill Sans","Gill Sans MT",Myriad Pro,Myriad,"Liberation Sans","Nimbus Sans L",Tahoma,Geneva,"Helvetica Neue",Helvetica,Arial,sans-serif;
  --side-padding: 12px;
  --content-from-top: 80px;

  --header-color: #202020;
  --primary-font-color: #424242;
  --secondary-font-color: #757575;
  --anchor-color: #5f9b65;
  
  --element-background-color: #fbfbfb;
  --page-background-color: #f8f8f8;

  --border-color: rgba(72,94,144,0.23);
  --code-background: #1e1e1e;
  --highlighted-search-result: #e9ff00;
}