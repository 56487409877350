html {
  font-size: 13px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  background-color: var(--page-background) !important;
  height: 100%;
  text-rendering: optimizeLegibility;
}

#main {
  min-height: calc(100vh - 50px);
  background-color: var(--page-background-color);
}

footer {
  height: 50px;
  font-size: 1.4rem;
  text-align: center;
  background-color: var(--element-background-color);
  box-shadow: 0 -1px 1px rgb(0 0 0 / 5%), 0 -1px 1px rgb(0 0 0 / 5%);
  display: flex;
  color: var(--header-color);
  font-family: var(--main-font-family);
}

footer div {  
  margin:auto;
}

header {
  height: 64px;
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  background-color: var(--element-background-color);
  color: var(--header-color);
  -webkit-font-smoothing: antialiased;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.header-visible {
  margin-top: 0px;
  transition: margin 500ms ease;
  z-index: 1;
}

.header-invisible {
  margin-top: -64px;
  transition: margin 500ms ease;
  z-index: 1;
}

.hamburger {
  font-size: 28px;
  height: 1em;
  width: 1em;
  align-items: center;
  margin-right: 8px;
  padding: 12px;
  cursor: pointer;
}

.name {
  font-size: 22px;
  align-items: center;
  font-family: var(--main-font-family);
  width: 100%;
}

.search-icon {
  font-size: 20px;
  padding: 12px;  
  cursor: pointer;
}

.search-input {
  width: 95px;
  height: 36px;
  appearance: none;
  border: none;
  outline: none;
  padding-left: 12px;
  margin-right: 5px;
  padding-right: 30px;
}

/* ARTICLE */
.article-previews {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--content-from-top);
  padding-bottom: 20px;
}

.article-preview {  
  font-family: var(--main-font-family);
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
  padding-top: var(--side-padding);
  background-color: var(--element-background-color);
  border-radius: 3px;
  border: solid 1px var(--border-color);
  margin-bottom: 1em;
}

h1 {
  color: var(--primary-font-color);
  font-size: 3rem;
  margin-top: 0;
  font-weight: 400;
  line-height: 1.30357em;
  margin-left: 0;
  margin-bottom: 0;
  letter-spacing: -.02em;
}

h2 {
  color: var(--primary-font-color);
  font-size: 2.5rem;
  margin-top: 0;
  font-weight: 400;
  line-height: 1.30357em;
  margin-left: 0;
  margin-bottom: 0;
  letter-spacing: -.02em;
}

h3 {
  color: var(--primary-font-color);
  font-size: 2rem;
  margin-top: 0;
  font-weight: 400;
  line-height: 1.30357em;
  margin-left: 0;
  margin-bottom: 0;
  letter-spacing: -.02em;
}

p {
  color: var(--primary-font-color);
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.4rem;
  word-break: break-word;
  font-weight: 400;
  font-family: var(--main-font-family);
  line-height: 2rem;
}

.article-header-wrapper {
  display: grid;
  grid-template-columns: 1fr 36px;
}

.title {
  margin-bottom: 15px;
}

.sub-header {
  color: var(--primary-font-color);
  line-height: 2rem;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 15px;
}

.author {
  font-weight: 600;
  margin-right: 20px;
}

.date {
  color: var(--secondary-font-color);
  margin-right: 20px;
  display: inline-block;
}

.read-length {
  color: var(--secondary-font-color);
  display: inline-block;
}

.views {
  color: var(--secondary-font-color);
  margin-right: 20px;
}

.tags {
  margin-top: 8px;
  display: flex;
  margin-bottom: 24px;
}

.tag {
  color: var(--secondary-font-color) !important;
  border: solid 1px var(--border-color);
  background-color: var(--element-background-color);
  padding: 5px;
  margin-right: 3px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 8px;
  border-radius: 3px;
  font-size: 1.3rem;
}

.article-view {
  margin-top: -24px;
  padding-bottom: 2em;
}

.end-of-article {
  text-align: center;
}

/* MOBILE */
@media (max-width: 750px) {
  .search-results-width {
    width: 100%;
  }

  .overflow-hide {
    overflow: hidden;
  }

  .toc {
    display: none;
  }

  .article-wrapper {
    background-color: var(--element-background-color);
    padding-left: 5px;
    padding-right: 5px;
    padding-top: var(--content-from-top);
    color: var(--primary-font-color);
  }
}

/* TABLET */
@media (min-width: 750px) and (max-width: 1000px) {
  .search-results-width {
    width: 470px;
  }

  .overflow-hide {
    overflow: auto;
  }

  .article-wrapper {
    padding-top: var(--content-from-top);
    background-color: var(--element-background-color);
    display: grid;
    grid-template-areas:
      "... ... .... hero .... ..."
      "... ... .... title .... ..."
      "... toc gap1 content gap2 ...";
    grid-template-columns: 1fr minmax(200px, 220px) minmax(0px, 12px) minmax(min-content, 720px) minmax(0px, 12px) 1.5fr;
    color: var(--primary-font-color);
  }
}

/* DESKTOP */
@media (min-width: 1000px) {
  .search-results-width {
    width: 470px;
  }

  .overflow-hide {
    overflow: auto;
  }

  .article-wrapper {
    padding-top: var(--content-from-top);
    background-color: var(--element-background-color);
    display: grid;
    grid-template-areas:
      "... ... .... hero .... ..."
      "... ... .... title .... ..."
      "... toc gap1 content gap2 ...";
    grid-template-columns: 1fr minmax(200px, 270px) minmax(0px, 100px) minmax(min-content, 720px) minmax(0px, 100px) 1.5fr;
    color: var(--primary-font-color);
  }
}

.toc {
  width: unset;
  position: unset;
}

.share-wrapper {
  margin-top: 80px;
  margin-bottom: 80px;
  border-top: 1px solid var(--secondary-font-color);
  padding-top: 20px;
  text-align: center;
}

.share {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 2rem;
  margin-top: 20px;
}

.share > span {
  margin-left: 10px;
  margin-right: 10px;
}

.share i {
  cursor: pointer;
}

.toc-title {
  display: inline-block;
  max-width: 100%;
  margin-right: 8px;
  margin-bottom: 8px;
  padding-top: 3px;
  border-bottom: solid 1px rgba(0,0,0,.1);
  padding-bottom: 14px !important;
  margin-left: 0px !important;
}

.toc-item {
  color: var(--header-color);
  position: relative;
  font-size: 1.16rem;
  font-weight: 400;
  font-family: var(--second-font-family);
  line-height: 1.5rem;
  color: var(--secondary-font-color);
  display: block;
  padding-top: 6px;
  line-height: 1.2em;
  padding-bottom: 6px;
}

.sticky-block-schroller {
  top: 92px;
  height: 80vh;
  position: sticky;
  font-size: 12px;
  direction: rtl;
  text-align: left;
  overflow-y: auto;
  line-height: 1;
  margin-left: 1px;
  padding-left: 16px;
}

.sticky-block {
  direction: ltr;
}

.toc-item-highlighted {
  color: var(--primary-font-color);
  margin-left: 5px;
}
/* 
.toc-item-pointer {
  color: black;
}

.toc-item-pointer::before {
  top: 6px;
  left: -10px;
  content: ">";
  position: absolute;
} */

.menu-wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1000;
  position: fixed;  
}

.menu-wrapper-active {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  position: fixed;
}

.menu-background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  touch-action: none;
  background-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  transition: background-color 200ms ease;
}

.menu-background-active {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  touch-action: none;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  transition: background-color 200ms ease;
}

.menu {
  width: 0px;
  transition: width 200ms ease;
  padding-top: 10px;
  overflow: hidden;
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: none;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  background-color: var(--element-background-color);
}

.menu-active {
  width: 220px;
  transition: width 200ms ease;
  padding-top: 10px;
  overflow: hidden;
  left: 0;
  right: auto;
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: none;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);
  background-color: var(--element-background-color);
}

.menu-items {
  display: flex;
  max-width: 220px;
  flex-direction: column;
  justify-content: space-around;
}

.menu-item-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px;
}

.menu-item-icon {
  margin-right: 16px;
  display: inline;
  color: var(--secondary-font-color);
}

.menu-item-icon svg {
  width: 30px;
  height: 30px;
  color: var(--secondary-font-color);
}

.menu-item-icon i {
  width: 30px;
  height: 30px;
}

.menu-item-text {
  color: var(--primary-font-color);
  font-size: 1.16rem;
  font-weight: 400;
  font-family: var(--second-font-family);
  line-height: 1.5rem;
  text-transform: none !important;
}

.last-menu-item {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: absolute;
  bottom: 40px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

.logo-polygon{
  fill: var(--secondary-font-color);
  stroke: var(--secondary-font-color);
  stroke-width: 6;
}

.about-image-wrapper {
  float: right;
  border-radius: 100%;
}

.about-image-wrapper img {
  border-radius: 100%;
}

.about-banner {
  top: 65px;
  right: 0;
  width: 100vw;
  height: 380px;
  position: absolute;
  background-color: rgba(0,0,0,0.5);
  z-index: -100;
}

.about-banner img {
  width: 100vw;
}

.banner-shade {
  width: 100%;
  bottom: 0;
  height: 150px;
  z-index: 1;
  position: absolute;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 42%, rgba(255, 255, 255, 0) 100%);
}

.search-results {
  position: fixed;
  top: 64px;
  bottom: 0px;
  right: 0px;
  color: var(--header-color);
  background-color: var(--element-background-color);
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  padding-top: 8px;
  border-bottom: solid 1px rgba(0,0,0,.3);
  overflow-y: scroll;
  z-index: 1;
}

.search-result {
  padding-top: 8px;
  padding-left: 16px;
  border-bottom: solid 1px rgba(0,0,0,.3);
  padding-right: 16px;
  padding-bottom: 8px;
}

.close-search {
  font-size: 2.7em;
  margin-left: -1em;
  background-color: transparent;
  z-index: 1;
  cursor: pointer;
}

.close-search i {
  color: black;
}

.highlighted-search-result {
  background-color: var(--highlighted-search-result) !important;
}

.copied {
  font-family: var(--second-font-family);
  background-color: var(--border-color);
  color: var(--primary-font-color);
  border-radius: 20px;
  padding: .6em .8em;
  margin-left: -3em;
  font-size: .8em;

  opacity: 0;
  transition: opacity 1000ms ease;
}

.copied-active {
  font-family: var(--second-font-family);
  background-color: var(--border-color);
  color: var(--primary-font-color);
  border-radius: 20px;
  padding: .6em .8em;
  position: static;

  opacity: 1;
  transition: opacity 1000ms ease;
}

.publication-media {
  text-align: center;
  margin-top: 1em;
  width: 100%;
}

figure {
  margin: 2em auto;
  text-align: center;
}

figure img {
  max-width: 100%;
}

figcaption {
  font-weight: 600;
  font-size: 1.35rem;
  font-family: var(--second-font-family);
  margin-bottom: 15px;
  color: var(--primary-font-color);
}

a {
  color: var(--anchor-color);
  text-decoration: none;
}

.link {
  color: var(--anchor-color);
  text-decoration: none;
}

ol {
  font-size: 1.4rem;
  color: var(--primary-font-color);
}

ul {
  font-size: 1.4rem;
  color: var(--primary-font-color);
}

/* CODE STYLES */
.view-lines {
  background-color: var(--code-background);
  padding: 10px;
  border-radius: 5px;
  font-family: Consolas, 'Courier New', monospace; 
  font-weight: normal; 
  font-size: 14px; 
  font-feature-settings: 'liga' 0, 'calt' 0; 
  line-height: 19px; 
  letter-spacing: 0px;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 15px;
}
.showUnused {
  opacity: .75;
}
.mtk1 {
  color: #d4d4d4;
}
.mtk4 {
  color: #6a9955;
}
.mtk5 {
  color: #569cd6;
}
.mtk6 {
  color: #b5cea8;
}
.mtk8 {
  color: #d7ba7d;
}
.mtk9 {
  color: #9cdcfe;
}
.mtk11 {
  color: #ce9178;
}
.mtk13 {
  color: #808080;
}
.mtk15 {
  color: #dcdcaa;
}
.mtk16 {
  color: #4ec9b0;
}
.mtk17 {
  color: #c586c0;
}
.mtk18 {
  color: #4fc1ff;
}
.bracket-highlighting-0 {
  color: #ffd700;
}