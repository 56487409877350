.dark {
  --main-font-family: warnock-pro, Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  --second-font-family: GreekFallback,Calibri,"Gill Sans","Gill Sans MT",Myriad Pro,Myriad,"Liberation Sans","Nimbus Sans L",Tahoma,Geneva,"Helvetica Neue",Helvetica,Arial,sans-serif;
  --side-padding: 12px;
  --content-from-top: 80px;
  
  --header-color: #ffffff;
  --primary-font-color: #f2f2f2;
  --secondary-font-color: #757575;
  --anchor-color: #5f9b65;

  --element-background-color: #1e1e1e;
  --page-background-color: #121212;

  --border-color: rgba(72,94,144,0.23);
  --code-background: #121212;
  --highlighted-search-result: #ffeb0063;
}